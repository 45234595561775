import { FlatList, Image, styled, Text, useDripsyTheme, useSx, View } from 'dripsy';
import React, { useEffect, useMemo, useState } from 'react';
import { ImageStyle, ViewProps } from 'react-native';
import { Skeleton } from '../skeleton';
import {
    hideMoreText,
    validURL,
} from '../../util/helper';
import { MotiPressable } from 'moti/interactions';
import { Link } from '../link';
import { Platform } from 'react-native';
import { useRouter } from 'solito/router';
import { useSelector } from 'react-redux';
import { Button } from 'app/components/button/common-button';
import { Icon } from 'app/components/icon/icon';
import { useCurrentRoute } from 'app/redux/hooks/use-current-route';
import { makeUseAxiosHook } from 'app/redux/store';
import Chip from '../chips';
import { I18Text } from 'app/provider/i18-provider';
import { getClientConfig } from '../../redux/memoizedSelectors';
import Dialog from '../dialog';
import DialogTitle from '../dialog/dialog-titel';
import DialogAction from '../dialog/dialog-action';
import { TextArea } from '../text-area';
import { useAlert } from '../../redux/hooks/alert';
import { getUser } from '../../redux/memoizedSelectors';
import { useToast } from 'app/redux/hooks/use-toast';
// import { config } from 'app/redux/config';

export interface ClassRoomCardProps extends ViewProps {
    id?: string;
    _id?: number;
    author?: string;
    showAuthor?: boolean;
    coverImageStyle?: ImageStyle;
    nameStyle?: any;
    authorStyle?: any;
    viewStyle?: any;
    user?: any;
    name: string;
    cover: string;
    avatar: string;
    totalNumberOfMembers?: string;
    totalNumberOfModerators?:string;
    instituteName?: string;
    slug?: string;
    moderator: any;
    billing: boolean;
    classroomCheck: any;
    centerId: any;
    additionalInfo?: any
    classroomTemplateId?: any
    templateDetails?: any
    iAmAMemberOfClassroom?: boolean
    iAmAModeratorOfClassroom?: boolean
    iAmAnAdminOfClassroom?: boolean
    cardType: string
    onPress?: any;
    totalNumberOfModule?: any;
    onEditClick?: any;
    setArchive?: any;
    archived?: boolean;
    tags?: Array<string>;
}

export const ClassRoomCard = styled(
    ({
        name,
        // cover,
        avatar,
        totalNumberOfMembers,
        totalNumberOfModerators,
        style,
        showAuthor = true,
        author,
        authorStyle,
        instituteName,
        slug,
        moderator,
        billing,
        classroomCheck,
        centerId,
        additionalInfo,
        cardType = 'classroom',
        onPress,
        onEditClick,
        setArchive,
        archived,
        tags = [],
        ...classroomRestData
    }: ClassRoomCardProps) => {

        const sx = useSx();
        const { theme }: any = useDripsyTheme();
        const { push } = useRouter();
        const currentRoute = useCurrentRoute();
        const sendMessage = useToast();
        const[openAddRemarkModal,setRejectRemarkModal]=useState(false);
        const [remarkValue,setRemarkValue]=useState<any>();
       

        const Alert = useAlert();
        const { user } = useSelector(getUser);

        const { clientConfig: clientConfigData } = useSelector(
            getClientConfig
        );

        const [{ data: centerInfo, loading: centerloading }, getCenter] = makeUseAxiosHook(
            'classroom', { manual: true }
        )(
            {
                url: `/api/v1/classroom-center/${centerId}`,
                method: 'GET',
            },
        );
        const [{ data: remarkData, loading: remarkLoading, error: remarkError },
            rejectRequest,
        ]=makeUseAxiosHook('ttss',{
            manual:true
        })({
            url:'/api/v1/training-reject-requests',
            method: 'POST'
        });

        useEffect(() => {
            if (centerId && centerloading) return;
            if (centerId) getCenter();
        }, [centerId]);

        const tabKey: any = useMemo(() => {
            const modules = Object.values(
                classroomRestData?.templateDetails?.modules ??
                clientConfigData?.classroomTabs
            )?.filter((obj) => typeof obj !== 'boolean' && !Array.isArray(obj));

            const activeTabs: any = modules.filter(
                (module: any) =>
                    typeof module !== 'boolean' &&
                    !Array.isArray(module) &&
                    module?.visible
            );

            const structuredCourseModule: any = modules.find(
                (module: any) =>
                    module?.key === 'structured-course' && module?.visible
            );

            const defaultTabKey =
                structuredCourseModule?.key ||
                (activeTabs.length > 0 && activeTabs[0]?.key);
            return defaultTabKey;
        }, [clientConfigData.classroomTabs, classroomRestData.templateDetails]);


        const handleClassroomToBuy = async () => {
            classroomCheck.purchaseType === 'product'
                ? push(`/client-checkout/product/${classroomCheck.productTypeId}`)
                : push(`/client-subscription?continue=${currentRoute}&subscriptionId=${classroomCheck?.productTypeId}`);
        };
        useEffect(() => {
            if (remarkData && !remarkLoading) {
              sendMessage({
                message: 'Member Rejected Successfully',
                type: 'success',
              });
            }
            if (remarkError)
              if (
                (remarkError && !remarkLoading)
              ) {
                sendMessage({
                  message: remarkError ? remarkError?.response?.data?.message :'Something went wrong, Please try again',
                  type: 'error',
                });
              }
          }, [remarkData, remarkError]);

        const center = (centerInfo?.name ? `${centerInfo?.name ? centerInfo.name : ''} ${additionalInfo?.data?.venue_id ? ` - ${additionalInfo.data.venue_id}` : ''}` : instituteName) || '';

       

        return (
            <View>
                <MotiPressable
                onPress={() => {
                    if (onPress) {
                        onPress();
                    } else {
                        cardType === 'forum'
                            ? push(
                                '/forums/[forumSlug]/[pageType]',
                                `/forums/${slug}/post`
                            ) :
                            cardType === 'moduleManagement' ?
                                push(
                                    '/module-manage/[moduleSlug]/[pageType]',
                                    `/module-manage/${slug}/module-structure`
                                )
                                : push(
                                    '/classrooms/[classroomSlug]/[pageType]',
                                    `/classrooms/${slug}/${tabKey}`
                                );
                    }

                }}
                animate={useMemo(
                    () => ({ hovered, pressed }) => {
                        'worklet';

                        return {
                            borderColor: (hovered || pressed) ? theme?.colors['$primary'] : theme?.colors['$white'],
                            borderWidth: 1
                        };
                    },
                    []
                )}
                style={[sx({
                    margin: [1, 2],
                    minHeight: [170],
                    paddingX: [2, 3],
                    paddingY: 2,
                    borderRadius: 6,
                    backgroundColor: '$white',
                    shadowColor: '#000',
                    shadowOffset: {
                        width: 0,
                        height: 1,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 2.22,
                    elevation: 3,
                    justifyContent: 'space-between',
                    minWidth: [320, 360],
                }), {
                    borderWidth: 0.1,
                },
                    style]}
            >
                <View
                    style={[sx({
                        flexDirection: 'row',
                        flex: 1,
                        paddingY: 2,
                        minHeight: 90
                    })]}
                >
                    <View style={sx({
                        borderColor: '#C9C9C9',
                        borderWidth: 1,
                        borderRadius: 7,
                        marginRight: [3],
                        backgroundColor: '#F2F2F2',
                        height: 90,
                        padding: 1,
                    })}>
                        <Image
                            source={validURL(avatar) ? { uri: avatar } : require('../../assets/blackboard.png')}
                            resizeMode='cover'
                            style={sx({
                                width: 70,
                                height: 80,
                                borderRadius: 5
                            })}
                        />
                    </View>

                    <View
                        style={sx({
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            flex: 1,
                        })}
                    >
                        <View
                            style={sx({
                                flex: 1,
                                width: '100%',
                            })}
                        >
                            <View style={sx({ flexDirection: 'row', justifyContent: 'space-between' })}>
                                <Text
                                    numberOfLines={2}
                                    ellipsizeMode="tail"
                                    lineBreakMode="clip"
                                    style={sx({
                                        fontWeight: '600',
                                        fontSize: [13, 17],
                                        lineHeight: [17, 21],
                                        flexShrink: 1,
                                    })}
                                >
                                    {name + (additionalInfo?.data?.ref_planner_id ? ` - ${additionalInfo?.data?.ref_planner_id}` : '')}
                                </Text>

                                {onEditClick ? <Button
                                    startIcon={<Icon name="editPen" fill={['$secondary']} />}
                                    onPress={() => {
                                        onEditClick();
                                    }}
                                    variantType="text"
                                    variantColor="secondary"
                                    style={{ height: 20 }}
                                /> : <></>}
                            </View>

                            {centerloading ? <Skeleton /> : <Text>{center ?? ''}</Text>}

                            {tags?.length ?
                                <View
                                style={sx({ marginTop:10 })}
                                >
                                    <FlatList
                                    horizontal
                                        data={tags}
                                        renderItem={
                                            (data: any) =>
                                            (<Chip hideDelete={true} label={data.item}/>
                                            )
                                        }
                                        contentContainerStyle={{
                                            flexDirection: 'row',
                                            flexWrap: 'wrap',
                                            marginBottom: 10,
                                            maxWidth: 400,
                                            padding:1
                                        }}
                                    />
                                </View>:<></>}
                        </View>
                    </View>
                </View>

                <View
                    style={[sx({
                        borderTopWidth: 1,
                        borderTopColor: '#F4F4F4',
                        marginTop: 2,
                        flexDirection: ['row'],
                        justifyContent: 'space-between',
                        alignItems: ['center'],
                        flexWrap: 'wrap',
                        paddingTop: showAuthor ? 0 : 2,
                        minHeight: [30, 30, 40]
                    }),
                    Platform.select({
                        native: sx({
                            minHeight: 30,
                        }),
                    })
                    ]}
                >
                    {showAuthor && (
                        <Link
                            isNextLink={false}
                            disabled={Platform.OS !== 'web'}
                            style={sx({
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                margin: 0,
                                px: 0,
                                minWidth: [140,160],
                            })}
                            href={`/u/${moderator?.username}`}
                        >
                            <View
                                style={sx({
                                    marginRight: [2],
                                })}
                            >
                                <Image
                                    resizeMode='contain'
                                    source={require('../../assets/LN-E.png')}
                                    style={[
                                        sx({
                                            height: [30],
                                            width: [30],
                                            borderRadius: 15,
                                        })
                                    ]}
                                />
                            </View>
                            <Text
                                numberOfLines={2}
                                ellipsizeMode="head"
                                style={sx({
                                    fontSize: [12, 15],
                                    color: '$fontGrey',
                                    flexShrink: 1,
                                    ...authorStyle
                                })}
                            >
                                {author && hideMoreText(author, 15)}
                            </Text>
                        </Link>
                    )}
                    <View
                        style={{ 
                            flexDirection: 'row', display: 'flex', 
                            alignItems: 'center',
                            justifyContent:'center',
                            flexWrap: 'wrap',
                         }}
                    >
                    
                       { additionalInfo?.data?.trainingRef?<Button style={[sx({
                            marginRight:2,
                            width:60,
                            height:35,
                            }),
                            Platform.OS !== 'web' ?
                                sx({
                                 height:40,   
                                 width:70
                                }):{}
                            ]}>
                            <I18Text style={sx({
                            color: '#647C97',
                            fontSize: 15,
                        })}
                        onPress={()=>setRejectRemarkModal(true)}
                        >Reject</I18Text>
                        </Button>:<></>}
                 
                    {onEditClick && setArchive && 
                   (<Button 
                    variantType="text"
                    variantColor="secondary"
                    style={{ height: 20 }}
                    onPress={()=>setArchive((prev)=>!prev)} ><I18Text>{archived ? 'Unarchive' : 'Archive'}</I18Text></Button>)}
                        {onPress ? 
                        <I18Text style={sx({
                            color: '#647C97',
                            fontSize: 15
                        })} label='{module} Module'
                        data={{ module :`${ classroomRestData?.totalNumberOfModule ?? 0 }` }}/>
                         : 
                         <View>
                        <I18Text style={sx({
                            color: '#647C97',
                            fontSize: 15,
                        })} label='{member} Trainer'
                        data={{ member :`${ totalNumberOfModerators ?? 0 }` }}/>
                        <I18Text style={sx({
                            color: '#647C97',
                            fontSize: 15
                        })} label='{member} Participants'
                        data={{ member :`${ totalNumberOfMembers ?? 0 }` }}/>
                        </View>
                        }
                    </View>
                    {
                        billing && classroomCheck?.isAvailableForPurchase ?
                            <Button
                                onPress={() => handleClassroomToBuy()}
                                variantType='outlined'
                                startIcon={
                                    <Icon name='cart'
                                        width={18}
                                        height={18}
                                    />
                                }
                                startIconStyle={{
                                    marginRight: 0
                                }}
                                style={{ height: 30 }}
                            >
                                <I18Text>Buy now</I18Text>
                                </Button> : <></>
                    }
                </View>
            </MotiPressable>
            {openAddRemarkModal ? (
        <Dialog
          modalVisible={openAddRemarkModal}
          onCloseModal={() => {
            setRejectRemarkModal(false);
          }}
          styles={sx({
            borderRadius: 5,
            minWidth: [350, 500],
            maxWidth: [350, 500],
          })}
        >
          <DialogTitle styleContainer={{ paddingTop: '2%', paddingLeft: '2%' }}>
            Add remark (optional)
          </DialogTitle>
          <View
            style={{
              padding: '2%',
            }}
          >
            <TextArea
              numberOfLines={3}
              onChangeText={(value) => {
                setRemarkValue(value);
              }}
            />
          </View>
          <DialogAction>
            <Button
              onPress={() => {
                Alert({
                  buttons: [
                    {
                      name: 'Cancel',
                      onPress: () => {
                        setRejectRemarkModal(false);
                      }
                    },
                    {
                      name: 'Okay',
                      onPress: () => {
                            rejectRequest({
                                data: {
                                    status: 1,
                                    // memberId: item?.memberId,
                                    training: additionalInfo?.data?._id,
                                    user: user?._id,
                                    remarks: remarkValue,
                                  },
                          });
                        setRejectRemarkModal(false);
                        setRemarkValue('');
                      },
                    },
                  ],
                  message: 'Are you sure you wish to delete this member?',
                  title: 'Delete Member',
                  type: 'warn',
                });
              }}
            >
             <I18Text style={[sx({ color:'#fff' })]}>Submit</I18Text>
            </Button>
          </DialogAction>
        </Dialog>
      ) : null}
            </View>
            
        );
    }
)({});